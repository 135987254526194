
import Helmet from "preact-helmet";
import { lazy, Suspense } from 'preact/compat';
import style from "./style.css";
// import star from '../../assets/icons/'
const CDForm = lazy(() => import('../../components/canada-district/home/cdForm'));
const CDReview = lazy(() => import('../../components/canada-district/home/cdReview'));
const CDService = lazy(() => import('../../components/canada-district/home/cdService'));
const CDBannerVersion = lazy(() => import('../../components/canada-district/home/cdbannerversion'));
const CDLayout = lazy(() => import('../../components/canada-district/layout'));
const CDHomeVersion = () => {
  return (
    <>
      <Helmet title="Canada District" 
      link={[
                    {rel:"icon", type:"image/x-icon" ,href:"/assets/thankyou/favicon.ico"}
                ]} />
      <Suspense>
      <CDLayout>
        <div class={style.cdHome}>
          <Suspense>
          <CDBannerVersion />
          </Suspense>
          <Suspense>
          <CDService />
          </Suspense>
          <Suspense>
          <CDForm />
          </Suspense>
          <Suspense>
          <CDReview />
          </Suspense>
        </div>
      </CDLayout>
      </Suspense>
    </>
  );
};

export default CDHomeVersion;
