import { Router } from 'preact-router'
import { useState, useEffect } from 'preact/hooks'
import Home from './routes/home'
import AsyncRoute from 'preact-async-route'
import CDHome from './routes/cd-home'
import CDHomeVersion from './routes/cd-home/homeversion'
import './style'
import 'react-phone-input-2/lib/style.css'
import UserContext from './context/userContext'
const App = () => {
  const [userGeoLocation, setUserGeoLocation] = useState({})
  if (typeof window !== 'undefined') {
    const location = window.location.pathname
    const origin = window.location.origin
    if (
      location === "/" &&
      (origin === "https://lp.uiscanada.com" ||
        origin === "http://localhost:3001")
    ) {
      window.location.href = "/lp/en/global/immigration/check/";
    } else if (location === "/" && origin === "https://lp.canadadistrict.com")
      window.location.href = "/lp/en/global/immigration/";
  }
  const originData = async () => {
    const res = await fetch("https://ipapi.co/json");
    const jsonData = await res.json();
    setUserGeoLocation(jsonData || {});
  };
  useEffect(() => {
    originData()
  }, [])
  return (
    <div id='app'>
      <main>
        <UserContext.Provider value={{ userGeoLocation, setUserGeoLocation }}>
          <Router
          onChange={() =>
            typeof window !== 'undefined' && window.scrollTo(0, 0)
          }
        ><Home path="/lp/en/global/immigration/check/" />
            <AsyncRoute
              path='/thank-you/'
              getComponent={() =>
                import("./routes/thankyou/index.js").then(
                  (module) => module.default
                )
              }
            />

            <AsyncRoute
              path='/result/'
              getComponent={() =>
                import("./routes/result/result").then(
                  (module) => module.default
                )
              }
            /></Router><Router
            onChange={() =>
              typeof window !== 'undefined' && window.scrollTo(0, 0)
            }
          ><CDHome path="/lp/en/global/immigration/" />
          <CDHomeVersion path="/lp/en/global/immigration-2/" />
          <AsyncRoute
            path="/thank-you/email_verification/"
            getComponent={() =>
              import('./components/canada-district/thank-you').then(
                (module) => module.default,
              )
            }
          />
          <AsyncRoute
            path="/thank-you/unsubscribe/"
            getComponent={() =>
              import('./components/canada-district/thank-you').then(
                (module) => module.default,
              )
            }
          />
          <AsyncRoute
              path="/thank-you/"
              getComponent={() =>
                import("./components/canada-district/thank-you").then(
                  (module) => module.default
                )
              }
            />
            </Router>
        </UserContext.Provider>
      </main>
    </div>
  );
};

export default App;
